import React from "react"

let setUp = false;
const listeners = [];

export default class Responsive extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { big: true };
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  componentDidMount() {
    if (!setUp) {
      window.onresize = () => {
        const big = window.innerWidth > 800;
        listeners.forEach(f => f(big));
      }

      setUp = true;
    }

    listeners.push((v) => {
      if (!this.unmounted) {
        this.setState({ big: v })
      }
    });
    
    window.onresize();
  }

  render() {
    const { comp } = this.props
    const { big } = this.state;

    return comp(big);
  }
}
